<template>
  <form
    action
    data-vv-scope="body-form"
    @submit.prevent="validateForm('body-form')"
  >
    <v-row id="body_selector" justify="center">
      <v-col
        cols="12"
        md="6"
        v-bind:class="['body', { error_body_selector: false }]"
      >
        <h3
          style="
            text-transform: uppercase;
            font-weight: lighter;
            color: var(--v-primary-base);
            text-align: center;
          "
        >
          {{ title }}
        </h3>
        <!--
        <h5>
          {{ serviceSelected.body_part }} - {{ serviceSelected.sub_body_part }}
        </h5>
        -->
        <svg
          v-bind:class="{ 'error--text': body_error }"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="300 345 500 900"
          style="
            enable-background: new 0 0 1190.6 1683.8;
            width: 65%;
            margin: auto;
            display: inherit;
          "
          xml:space="preserve"
        >
          <a
            @click="
              serviceSelected.body_part != 'all' &&
              serviceSelected.body_part != 'brazo'
                ? null
                : select('brazo_izquierdo')
            "
            :class="customer.body_part === 'brazo_izquierdo' ? 'selected' : ''"
          >
            <path
              class="st0"
              v-bind:class="[
                {
                  disabled:
                    serviceSelected.body_part != 'all' &&
                    serviceSelected.body_part != 'brazo',
                },
              ]"
              d="M616.2,674.5c0,0-10.9-61.8-0.4-133.7l0-0.1c9.3,4.2,18.6,8.4,27.9,12.7c7.4,3.5,13.1,8.4,13.6,17.5
		        c0.3,4,1.3,8,1.9,12.1c1.9,12,0.8,23.9-0.7,35.9c-1,7.5-0.1,15.3-0.1,22.9c0,6.6,0.3,13.1,0,19.7c-0.3,9.1-2.1,18.2,0.9,27.2
		        c3,9.1,6.4,18.2,8.6,27.5c1.8,7.6,2.4,15.5,3.1,23.3c0.7,8.1,0.5,16.2,1.2,24.3c0.5,6.3,1.7,12.6,2.7,18.9c2,11.9,4,23.8,6.2,35.7
		        c1.6,8.5,3.6,16.9,12.7,21.3c3.5,1.7,6.7,4.8,8.9,8.1c2.7,4,4.2,8.8,6.4,13.2c1.5,3.1,3.3,6.1,5,9.2c0,1.7,0,3.3,0,5
		        c-5.3,1.9-11.4-0.9-14-6.5c-0.8-1.5-2.1-2.8-3.9-5.1c0,5.8-0.1,10.1,0,14.4c0.2,9.4,0.8,18.9,0.7,28.3c0,1.9-1.8,4.6-3.5,5.4
		        c-1.6,0.7-4.1-0.6-6.7-1.1c-2.5,1.8,2.5,10.2-5.8,8.4c-2.2-0.5-4-2.7-6.5-4.5c-3.9,2-5.9,1.3-7.4-3.9c-1.1-4-1.7-8.2-2.6-12.6
		        c-0.3,1.8-0.5,3.4-0.7,4.9c-6.4,0.3-6.4,0.3-7.7-5.8c-0.5-2.4-0.7-4.8-0.9-7.3c-0.7-8.7-1.5-17.4-1.9-26.1
		        c-0.3-6.3-0.8-12.8,0-19.1c1.1-8.4,1.2-16.4-2.1-24.3c-1.9-4.6-3.5-9.4-5-14.1c-1.5-4.8-2.2-9.9-4.2-14.5
		        c-7.6-16.8-14.7-33.7-18.5-51.8c-2.3-11.2-5-22.5-3-33.7c2-11.1-2.4-20.7-4.2-31L616.2,674.5z"
            />
          </a>

          <a
            @click="
              serviceSelected.body_part != 'all' &&
              serviceSelected.body_part != 'cabeza'
                ? null
                : select('cabeza')
            "
            :class="customer.body_part === 'cabeza' ? 'selected' : ''"
          >
            <path
              class="st0"
              v-bind:class="[
                {
                  disabled:
                    serviceSelected.body_part != 'all' &&
                    serviceSelected.body_part != 'cabeza',
                },
              ]"
              d="M580,520.3c-1.2-3.2-1-7-1.5-10.5c-1.2-9.2,0.6-18,4.8-26.2c0.4-0.8,0.8-2.2,1.3-2.2c5.7-0.2,5.3-4.2,6.1-8.3
		        c1.6-7.5,2-14.5-0.9-21.9c-1.3-3.3,0.4-7.7,0.7-11.7c0.3-3.6,0.5-7.2,1-10.8c0.4-3.2,1-6.4,1.6-10c-0.7-0.2-1.9-0.5-3.4-0.9
		        c0.9-3.3-3.2-7.6,2.5-10c-2.8-1.5-6-2.4-8-4.4c-4-4.1-9.2-5.8-14.1-8c-2.7-1.2-5.7-1.5-8.6-2.2c-9,0-18,0-27,0
		        c-4.4,2.3-8.6,5-13.1,6.9c-4.4,1.8-6.9,4.1-8.2,9.2c-1.9,7.3-4.6,14.3-3.6,22.1c0.3,2.1,1.1,4.2,0.9,6.3c-0.5,4.8,1,9.2-1.6,14.5
		        c-3.7,7.6-1.8,16.8,1.4,24.9c0.6,1.5,4.2,1.9,6.5,2.7c0.5-0.4,0.9-0.8,1.4-1.2c0.5,10.8,0.7,21.6,1.6,32.4c0.3,3.8-0.3,6.9-1.7,9.5
		        l0.3,0.1c0,0,29.7,10.2,61.6-0.2L580,520.3z"
            />
          </a>

          <a
            @click="
              serviceSelected.body_part != 'all' &&
              serviceSelected.body_part != 'brazo'
                ? null
                : select('brazo_derecho')
            "
            :class="customer.body_part === 'brazo_derecho' ? 'selected' : ''"
          >
            <path
              class="st0"
              v-bind:class="[
                {
                  disabled:
                    serviceSelected.body_part != 'all' &&
                    serviceSelected.body_part != 'brazo',
                },
              ]"
              d="M476.7,667.7c0,0,11.1-57.7,0.6-124.5l0-0.1c-8.9,3.9-18.1,7.2-26.9,11.2c-9.7,4.3-12.9,13.2-13.9,22.7
		        c-1.3,11.3-1.6,22.7-2.1,34c-0.1,2.6,0.2,5.3,1,7.8c2.5,7.6-1.2,14.5-1.6,21.7c-0.5,8.9-1.2,18.1,0,26.9c1.4,10.5,0,20.2-3.9,29.8
		        c-3.4,8.3-7.4,16.4-9.8,25c-1.9,6.7-1.9,13.9-2.7,20.9c-2,15.6-3.6,31.2-6.2,46.7c-2,12.3-4.7,24.5-8.1,36.5
		        c-1.4,5.1-4.2,10.7-8.1,14.2c-7.2,6.5-9.1,15-11.8,23.5c-1.1,3.5-2.6,6.8-4,10.2c0,1.7,0,3.3,0,5c2.5,0.2,5,0.4,8,0.7
		        c-0.4,4.8-0.8,10-1.4,15.1c-0.4,3.7-1.6,7.5-1.4,11.1c0.1,2.1,1.9,5.1,3.7,5.8c3.7,1.5,5.6-1.2,7.1-4.7c0,2.7,0,5.3,0,8
		        c-0.1,2.9,1.1,4.6,4.2,5c3.6,0.5,5.2-1.4,5.8-4.5c1.1-6.2,2.2-12.4,4-18.5c0,5.8,0,11.6,0,17.3c7.2,2.6,10.4-0.5,11-10.2
		        c0.4-6.7,1-13.4,2-20.1c0,4-0.1,7.9,0,11.9c0.2,3.3-0.6,7.5,4.3,7.9c4.8,0.4,6.1-3.8,6.5-7.3c0.6-5.4,0.1-11,0.1-16.5
		        c0-4.7-0.6-9.5,0.2-14.1c1.5-8.5,1.9-16.8-0.1-25.2c-1.3-5.6-0.9-10.9,1.1-16.3c2.7-7.3,5-14.8,7.8-22.1c2.9-7.4,5.9-14.8,9.3-22
		        c7.8-16.5,14.4-33.5,17.6-51.5c1.2-6.7,0-13.7,0.4-20.6c0.4-7.2,1.4-14.3,2.4-21.5c0.7-5.2,1.6-10.3,2.5-15.5
		        c0.2-1.3,0.7-2.6,1.1-3.9C475.8,667.8,476.3,667.8,476.7,667.7"
            />
          </a>

          <a
            @click="
              serviceSelected.body_part != 'all' &&
              serviceSelected.body_part != 'tronco'
                ? null
                : select('tronco')
            "
            :class="customer.body_part === 'tronco' ? 'selected' : ''"
          >
            <path
              class="st0"
              v-bind:class="[
                {
                  disabled:
                    serviceSelected.body_part != 'all' &&
                    serviceSelected.body_part != 'tronco',
                },
              ]"
              d="M544.1,873.2l-10-15.6c-11.6-17.8-26.5-33.1-43.9-45.2l-24.7-17.2l-0.1-0.1c1-4.9,1.9-9.8,2.9-14.8
		        c2.8-14.9,4.4-30.2,8.6-44.7c4.1-14.1,7-27.9,4.4-42.6c-1.5-8.5-3-16.9-4.6-25.4l0-0.1c0,0,11.1-57.7,0.6-124.5l0-0.1
		        c10.4-4.6,20.6-9.5,30.9-14.4c4.3-2,9.7-8.2,9.7-8.2s29.5,10.3,62.1-0.2c0.9,2.4,2.4,4.4,5.3,5.8c6.8,3.4,13.7,6.8,20.6,10
		        c3.3,1.5,6.6,3,9.9,4.5l0,0c-10.5,72,0.5,134.5,0.5,134.5l0,0.1c-0.8,1.6-1.3,3.2-1.5,4.9c-1.1,10.6-3.2,21.1-3,31.7
		        c0.2,13.6,2.1,27.2,3.5,40.7c0.8,7.3,1.8,14.6,3.2,21.8c1.3,6.9,3.4,13.7,4.8,20.5l0,0l-24.8,17.2c-17.4,12.1-32.3,27.4-43.9,45.2
		        L544.1,873.2L544.1,873.2z"
            />
          </a>

          <a
            @click="
              serviceSelected.body_part != 'all' &&
              serviceSelected.body_part != 'pierna'
                ? null
                : select('pierna_izquierda')
            "
            :class="customer.body_part === 'pierna_izquierda' ? 'selected' : ''"
          >
            <path
              class="st0"
              v-bind:class="[
                {
                  disabled:
                    serviceSelected.body_part != 'all' &&
                    serviceSelected.body_part != 'pierna',
                },
              ]"
              d="M544.6,872.6l10-15.3c11.6-17.8,26.5-33.1,43.9-45.2l24.3-16.8l0.6-0.4c0.3,1.5,0.6,3,0.8,4.5
		        c2.1,13.4,3.4,27,5.2,40.4c2.2,16.8,5.5,33.6,6.7,50.5c1.1,15.9,1.3,32.1-2,47.9c-3.5,17-5.6,33.8-4.2,51.4
		        c1.5,17.7,4.9,34.8,9,51.9c2.1,8.9,3,18.2,3.2,27.3c0.2,8.2-1.5,16.4-1.7,24.6c-0.6,17.8-0.7,35.5-1.1,53.3c0,0.8,0.1,1.7,0,2.5
		        c-2,9.4-0.8,18.5,1.9,27.7c1.2,4.2,2.2,8.7,2,13c-0.2,7.6,3.6,13.8,7.4,19.4c4.7,6.8,11.3,12.4,17.5,18c6.2,5.7,5.5,12.2-2.1,15.5
		        c-3.2,1.4-6.4,2.8-9.6,4.3c-7.3,0-14.7,0-22,0c-2.2-2.1-4.6-3.9-6.5-6.2c-1.8-2.1-3.7-4.4-4.4-6.9c-1.2-4.7-0.4-10.1-7.5-10.9
		        c-1.3-0.1-3.1-2.9-3.4-4.7c-0.5-2.7,0.4-5.6,0.3-8.4c-0.2-4.2-0.8-8.3-1.3-12.4c-0.7-6-1.4-11.9-1.9-17.9c-0.2-1.9,0.8-3.9,0.7-5.8
		        c-0.6-10-0.8-20.1-2.1-30.1c-1-7.9-2-16.3-5.4-23.2c-8.9-18-12.6-36.8-12.4-56.6c0-1.5-0.2-3-0.3-4.4c-0.2-8.1-1.4-16.4-0.4-24.3
		        c0.9-6.8-0.5-12.3-3.6-18.1c-3.2-6-6-12.4-7.5-19c-3.5-15.2-5.6-30.8-9.3-45.9c-3.6-14.5-8.3-28.7-12.8-43
		        c-2.4-7.7-5.6-15.2-8.3-22.8c-1.4-3.9-2.4-8-3.8-13.1L544.6,872.6z"
            />
          </a>
          <a
            @click="
              serviceSelected.body_part != 'all' &&
              serviceSelected.body_part != 'pierna'
                ? null
                : select('pierna_derecha')
            "
            :class="customer.body_part === 'pierna_derecha' ? 'selected' : ''"
          >
            <path
              class="st0"
              v-bind:class="[
                {
                  disabled:
                    serviceSelected.body_part != 'all' &&
                    serviceSelected.body_part != 'pierna',
                },
              ]"
              d="M465.4,795c-1,5.2-2,10.2-2.8,15.4c-1.2,7.5-1.5,15-2.4,22.5c-0.9,8.1-2.1,16.2-3.1,24.3
		        c-0.7,5.2-1.7,10.4-1.8,15.6c-0.2,16-0.5,32,0.1,48c0.3,8.7,2.5,17.3,3.7,26c0.9,6.3,2,12.7,2,19.1c0,13.5,0,27-1.1,40.4
		        c-0.6,7.1-3.6,14.1-5,21.2c-1.3,7.1-2.1,14.3-2.8,21.4c-0.6,6.4-1.3,12.9-0.9,19.3c0.5,10.6,1.8,21.3,2.9,31.9
		        c0.9,8.8,1.1,17.8,3.3,26.4c3.6,14.1,4.2,28.5,4.5,42.9c0.2,7.4-0.8,15.2-3.2,22.2c-1.8,5.5-6.5,10.1-9.9,15.2
		        c-4.6,6.8-11.4,11.1-18.2,15.5c-4.6,3-8.1,7.1-6.5,13.2c1.3,4.8,5.7,7.5,9.6,8c8.2,1,16.7,1.4,24.8,0.1c4.7-0.8,9.7-4.5,11.6-10.1
		        c2-5.9,4.4-10.9,11.8-11.7c4.2-0.5,6.4-6.3,6.1-10.9c-0.3-6.1-0.9-12.6,0.5-18.4c2.2-8.8,0.7-17.2,0-25.8
		        c-0.5-6.8,0.3-13.8,1.7-20.5c2.5-12.1,6.3-23.9,8.6-36c2.6-14,5.2-28.1,5.9-42.2c0.6-11.6-1.7-23.2-2.7-34.9
		        c-0.7-9.1,2.7-17.1,5.8-25.5c3.3-8.7,5.9-18,6.9-27.3c1.7-15,4.6-29.5,9.2-43.9c4.7-14.7,8.6-29.7,13.1-44.4
		        c1.8-5.9,4.2-11.6,6.9-18.8l-0.3-0.4l-9.9-15.3c-11.6-17.8-26.5-33.1-43.9-45.2l-24.7-17.2L465.4,795z"
            />
          </a>
        </svg>
      </v-col>
      <v-col
        cols="12"
        md="4"
        v-if="customer.body_part"
        v-bind:class="['inner', { error_body_selector: false }]"
        ref="inner_part"
      >
        <div style="margin-top: 110px"></div>
        <v-btn
          v-for="(inner_part, i) in innerParts"
          :key="i"
          block
          elevation="10"
          class="my-3"
          v-bind:class="{ 'error--text': inner_error }"
          v-text="innerPartTitle(inner_part)"
          :style="
            customer.inner_part === inner_part.title
              ? 'color: var(--v-gris4-base)'
              : 'color: var(--v-primary-base)'
          "
          :color="
            customer.inner_part === inner_part.title ? 'primary' : 'gris4'
          "
          :disabled="
            serviceSelected.sub_body_part != 'all' &&
              serviceSelected.sub_body_part != inner_part.part
          "
          @click="selectInnerPart(inner_part.title)"
        />
        <v-text-field
          outlined
          dense
          data-vv-validate-on="blur"
          v-validate="'required'"
          :error-messages="errors.collect('body-form.other')"
          data-vv-name="other"
          v-model="customer.inner_part_other"
          v-if="show"
        ></v-text-field>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "BodySelector",
  components: {
    piernaIzquierda: () => import("./body_parts/pierna_izquierda"),
    piernaDerecha: () => import("./body_parts/pierna_derecha"),
    tronco: () => import("./body_parts/tronco"),
    espalda: () => import("./body_parts/espalda"),
    cabeza: () => import("./body_parts/cabeza"),
    brazoIzquierdo: () => import("./body_parts/brazo_izquierdo"),
    brazoDerecho: () => import("./body_parts/brazo_derecho"),
  },
  props: ["type", "title"],
  mounted() {
    this.fetchMarketplace();
  },
  data() {
    return {
      show: false,
      inner_error: false,
      body_error: false,
      parts_list: [],
    };
  },
  computed: {
    ...mapState("inscriptions", ["customerState"]),
    ...mapGetters("inscriptions", ["serviceSelected"]),
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },

    innerParts() {
      if (this.customer) {
        switch (this.customer.body_part) {
          case "pierna_izquierda":
          case "pierna_derecha":
            return [
              { title: this.$t("body.thigh"), part: "muslo" },
              { title: this.$t("body.calf"), part: "gemelo" },
              { title: this.$t("body.knee"), part: "gemelo" },
              { title: this.$t("body.ankle"), part: "tobillo" },
              { title: this.$t("other"), part: "otro" },
            ];
          case "brazo_izquierdo":
          case "brazo_derecho":
            return [
              {
                title: this.$t("body.forearm"),
                part: "antebrazo",
              },
              {
                title: this.$t("body.full_arm"),
                part: "todo_brazo",
              },
              { title: this.$t("body.wrist"), part: "muneca" },

              { title: this.$t("body.biceps"), part: "biceps" },

              { title: this.$t("body.hand"), part: "mano" },

              { title: this.$t("other"), part: "otro" },
            ];
          case "tronco":
            return [
              {
                title: this.$t("body.right_pectoral"),
                part: "pectoralderecho",
              },
              {
                title: this.$t("body.left_pectoral"),
                part: "pectoralizquierdo",
              },

              {
                title: this.$t("body.abdomen"),
                part: "abdomen",
              },
              {
                title: this.$t("body.full_back"),
                part: "full_back",
              },

              { title: this.$t("other"), part: "otro" },
            ];
          case "cabeza":
            return [
              {
                title: this.$t("body.face"),
                part: "cara",
              },
              {
                title: this.$t("body.neck"),
                part: "cuello",
              },

              { title: this.$t("other"), part: "otro" },
            ];
        }
      }
      return [];
    },
    // marketplace() {
    //   if (this.customer.tattooer && this.customer.tattooer.id === -1)
    //     return false;
    //   let s = this.customer.studio.user.setting.find(
    //     (x) => x.key === "marketplace_active_" + this.type
    //   );
    //   if (s) {
    //     return s.value === "1";
    //   }
    //   return false;
    // },
  },
  methods: {
    select(parte) {
      console.log("PARTE1", parte);
      this.$set(this.customer, "body_part", parte);
      this.$set(this.customer, "inner_part", null);
      this.body_error = false;
      this.show = false;
      this.customer.inner_part_other = null;
      this.$nextTick(() => {
        this.$vuetify.goTo(this.$refs.inner_part);
      });
    },
    selectInnerPart(parte) {
      console.log("PARTE2", parte);
      this.$set(this.customer, "inner_part", parte);
      this.inner_error = false;

      if (parte === "Otro") {
        this.show = true;
      } else {
        this.show = false;
        this.customer.inner_part_other = null;
        this.$emit("selected", `${this.customer.body_part} - ${parte}`);
      }
    },
    async validateForm(scope) {
      //console.log("validateBodyForm");
      this.errors.clear();
      let select_val = true;
      if (this.customer.inner_part === null) {
        select_val = false;
        this.inner_error = true;
      } else {
        this.inner_error = false;
      }
      if (this.customer.body_part === null) {
        select_val = false;
        this.body_error = true;
      } else {
        this.body_error = false;
      }
      let result = await this.$validator.validateAll(scope);
      if (result && select_val) {
        this.$emit("validated", this.user);
        return true;
      } else {
        return false;
      }
    },
    innerPartTitle(innerPart) {
      let text = innerPart.title;
      if (this.marketplace) {
        let part = this.customer.body_part.split("_")[0];
        let price = this.parts_list.find(
          (x) => x.part === `${part} - ${innerPart.part}`
        );

        text += " - ";
        if (price)
          text += this.$t("from_price", {
            price: this.$n(price.price, "currency"),
          });
        else text += this.$t("tbd");
      }
      return text;
    },

    ...mapActions("marketplace", ["getAllMarketplace"]),
    fetchMarketplace() {
      this.getAllMarketplace({ filters: { marketplace: this.type } }).then(
        (response) => {
          this.parts_list = response;
        }
      );
    },
  },
};
</script>

<style lang="sass" scope>
#body_selector
  .error_body_selector
    &.body
      svg
        border: red solid 1px
        boder-radio: 5px
    &.inner
      .v-btn
        color: red !important

  .st0

    fill: var(--v-gris1-base) !important
    stroke: #272727
    stroke-width: 0.5
    stroke-miterlimit: 10
    &.disabled
      fill: var(--v-gris2-base) !important
    &:not(.disabled):hover
      fill: var(--v-primary-base) !important
      opacity: 0.9
  .selected path
    fill: var(--v-primary-base) !important

  .v-card
    &:hover
      border: var(--v-primary-base) 2px solid
      opacity: 0.9
  #Capa_1.error--text .st0
    fill: var(--v-login_error-base) !important
</style>
